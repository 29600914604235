.h1 {
  visibility: hidden;
  height: 0;
  margin: 0;
  padding: 0;
}

.nav {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  padding: 1rem;
}

.nav a {
  display: block;
  aspect-ratio: 1;
  font-size: 3rem;
  text-decoration: none;
  text-align: center;
  border-radius: 1rem;
  overflow: hidden;
  background-color: #333;
  color: #fff;
}

@media only screen and (max-width: 767px) {
  .nav a:nth-child(4n),
  .nav a:nth-child(4n+1) {
    background-color: #444;
  }
}

.nav a:hover {
  color: #aaa;
}

.nav a:hover .flip {
  transform: rotateY(180deg);
  transition-delay: unset;
}

.flip {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  transition: transform 0.6s;
  transition-delay: 0.3s;
  transform-style: preserve-3d;
  background: inherit;
}

.flip .icon,
.flip .text {
  display: block;
  position: absolute;
  backface-visibility: hidden;
  background: inherit;
}

.flip .text {
  transform: rotateY(180deg);
  font-size: 2rem;
  padding: .5rem 0;
}

@media only screen and (min-width: 768px) {
 .nav {
   grid-template-columns: repeat(4, 1fr);
   margin: 0 16vw;
 }

 .nav a:nth-child(8n+1),
 .nav a:nth-child(8n+3),
 .nav a:nth-child(8n+6),
 .nav a:nth-child(8n+8) {
   background-color: #444;
 }

}
